@use 'variables';
@use 'mixin';
@use 'mixins/button';
@use 'bootstrap/scss/mixins/breakpoints';

%d-flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.btn {
  --bs-border-radius: #{variables.$border-radius-input};
  padding: 4px 15px;
  font-weight: 700;
  font-size: 1rem;
  transition: 300ms all;
  cursor: pointer;

  &:not([class*='btn-std']) {
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 7%);
    border-radius: 5px;
  }
}

.btn-outline {
  border-color: var(--c);
  color: var(--c);
  background-color: var(--bg);
  min-height: 31px;
  min-width: 80px;

  &:hover {
    color: var(--c) !important;
    border-color: var(--c) !important;
  }
}

@each $name, $color in variables.$std-color {
  .btn-std-#{$name} {
    @include button.btn-std-pre-hsl($color);
  }

  .btn-std-2ry-#{$name} {
    @include button.btn-std-2ry-pre-hsl($color);
  }

  .btn-std-outline-#{$name} {
    @include button.btn-std-outline-pre-hsl($color);
  }

  .btn-outline-#{$name} {
    @include button.btn-std-outline-pre-hsl($color, $color, $color);
  }
}

.btn-square {
  align-items: center;
  display: flex;
  height: variables.$height-input-default;
  justify-content: center;
  padding: 0;
  width: variables.$height-input-default;
}

.btn-icon {
  --bs-btn-line-height: 1rem;
  --bs-btn-active-border-color: transparent;

  align-items: center;
  border: 0 !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  padding: 0;
}

.btn-std {
  @extend .btn-h-res;
  @extend %d-flex-center;

  padding: 0.625rem 1rem;

  &-sm {
    @extend .btn-h-sm-res;
    @extend %d-flex-center;

    font-size: 0.75rem;
    padding: 0.375rem 1rem;
  }

  .ladda-label {
    @extend %d-flex-center;
  }
}

/// Button with default color for primary style.
.btn-std-prim {
  @extend .btn-std-blue-sapphire;
  --bs-btn-bg: hsl(var(--std-color-primary));
  --bs-btn-border-color: var(--std-btn-border);
  --bs-btn-border-width: 1px;
  --bs-btn-color: var(--std-btn-text-primary);
  --bs-btn-hover-bg: var(--std-btn-hover-bg-primary);
  --bs-btn-hover-border-color: var(--std-btn-border);
  --bs-btn-hover-color: var(--std-btn-text-primary-hover);
  --bs-btn-active-bg: hsl(var(--std-color-primary));
  --bs-btn-active-border-color: var(--bs-btn-active-bg) !important;
  --bs-btn-active-color: var(--bs-btn-color);

  outline: {
    style: solid !important;
    width: 0px !important;
    color: transparent !important;
  };
  transition: 300ms all, outline-width 0ms, border-color 0ms;

  &:active,
  &.active,
  &:first-child:active {
    outline: {
      style: solid !important;
      width: 2px !important;
      color: var(--std-btn-active-outline-primary) !important;
    }
  }
}

.btn-std-2ry-hue-expire {
  @extend .btn-std-2ry-blue-sapphire;
  color: hsl(var(--std-color-3));
  background-color: hsl(var(--std-color-1));
  border-color: hsl(var(--std-color-1));

  &:hover {
    color: hsl(var(--std-color-3));
    background-color: hsl(var(--std-color-2));
    border-color: hsl(var(--std-color-2));
  }

  &:active,
  &.active,
  &:first-child:active {
    color: hsl(var(--std-color-5)) !important;
    background-color: hsl(var(--std-color-3)) !important;
    border-color: hsl(var(--std-color-3)) !important;
  }
}

/// Button with default color for secondary style.
.btn-std-2ry {
  @extend .btn-std-2ry-blue-sapphire;
  --bs-btn-bg: hsl(var(--std-color-secondary));
  --bs-btn-border-color: var(--std-btn-border);
  --bs-btn-border-width: 1px;
  --bs-btn-color: var(--std-btn-text-secondary);
  --bs-btn-hover-bg: var(--std-btn-hover-bg-secondary);
  --bs-btn-hover-border-color: var(--std-btn-border);
  --bs-btn-hover-color: var(--std-btn-text-secondary-hover);
  --bs-btn-active-bg: hsl(var(--std-color-secondary));
  --bs-btn-active-border-color: var(--bs-btn-active-bg) !important;
  --bs-btn-active-color: var(--bs-btn-color);

  outline: {
    style: solid !important;
    width: 0px !important;
    color: transparent !important;
  };
  transition: 300ms all, outline-width 0ms, border-color 0ms;

  &:active,
  &.active,
  &:first-child:active {
    outline: {
      style: solid !important;
      width: 2px !important;
      color: var(--std-btn-active-outline-secondary) !important;
    }
  }
}

.btn-std-2ry-hue {
  @extend .btn-std-2ry-blue-sapphire;
  color: hsl(var(--std-color-5));
  background-color: hsl(var(--std-color-8));
  border-color: hsl(var(--std-color-8));

  &:hover {
    color: hsl(var(--std-color-5));
    background-color: hsl(var(--std-color-9));
    border-color: hsl(var(--std-color-9));
  }

  &:active,
  &.active,
  &:first-child:active {
    color: hsl(var(--std-color-5));
    background-color: hsl(var(--std-color-10));
    border-color: hsl(var(--std-color-10));
  }
}

.btn-std-2ry-red {
  $color: map-get(variables.$std-color, 'red');
  $disabled: #eaeaed;
  $background: hsl(0, 100%, 96%);
  $hover: hsl(0, 94%, 87%);
  $active: adjust-color($color, $hue: 0, $saturation: -10%, $lightness: 20%);

  @include button.button-variant(
          $background: $background,
          $border: $background,
          $color: $color,
          $hover-background: $hover,
          $hover-border: $hover,
          $hover-color: $color,
          $active-background: $active,
          $active-border: $active,
          $active-color: if(lightness($color) > 50%, $color, white),
          $disabled-background: $disabled,
          $disabled-border: $disabled,
          $disabled-color: #bdbfc7
  );

  padding: 0.75rem 1.25rem;
}

/// Button with default color for outline style.
.btn-std-outline {
  @extend .btn-std-outline-blue-united-nations;
}

.btn-std-outline-prim {
  --bs-btn-border-color: var(--std-btn-outline-primary);
  --bs-btn-bg: #ffffff;
  --bs-btn-color: var(--std-btn-outline-primary);
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: hsl(var(--std-color-6));
  --bs-btn-hover-color: hsl(var(--std-color-6));
  --bs-btn-focus-shadow-rgb: var(--std-btn-outline-primary);
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: hsl(var(--std-color-7));
  --bs-btn-active-color: hsl(var(--std-color-7));

  @extend .btn-std-outline-blue-sapphire;
}

.btn-std-memoCancel {
  @extend .btn-std-blue-sapphire;
  --bs-btn-bg: hsl(var(--std-color-memoCancel));
  --bs-btn-border-color: var(--std-btn-border);
  --bs-btn-border-width: 1px;
  --bs-btn-color: var(--std-btn-text-memoCancel);
  --bs-btn-hover-bg: var(--std-btn-hover-bg-memoCancel);
  --bs-btn-hover-border-color: var(--std-btn-border);
  --bs-btn-hover-color: var(--std-btn-text-memoCancel-hover);
  --bs-btn-active-bg: hsl(var(--std-color-memoCancel));
  --bs-btn-active-border-color: var(--bs-btn-active-bg) !important;
  --bs-btn-active-color: var(--bs-btn-color);

  outline: {
    style: solid !important;
    width: 0px !important;
    color: transparent !important;
  };
  transition: 300ms all, outline-width 0ms, border-color 0ms;

  &:active,
  &.active,
  &:first-child:active {
    outline: {
      style: solid !important;
      width: 2px !important;
      color: var(--std-btn-active-outline-memoCancel) !important;
    }
  }
}

.btn-select {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 0 15px;
}

.btn-success {
  background: #03a9f3;
  border-color: #03a9f3;
  color: #f2f2f2;
}

.btn-primary {
  background-color: #252259 !important;
  color: #f2f2f2;
  border: none;
}

.btn-outline-primary {
  border-color: #252259;
  color: #252259;
}

.btn-green {
  background: #19bc9c;
  border-color: #19bc9c;
  color: white !important;
}

.btn-h {
  &-res {
    @include button.get-btn-h-res();

    height: var(--btn-height);
  }

  &-sm-res {
    @include button.get-btn-h-sm-res();

    height: var(--btn-height);
  }
}

.btn-outline-lightcoral {
  @include button.button-outline-variant(lightcoral);
}

.btn-outline-yale-blue {
  @include button.button-outline-variant(variables.$yale-blue);
}

.btn-square {
  &-res {
    @include button.get-btn-h-res();

    height: var(--btn-height);
    width: var(--btn-height);
  }

  &-sm-res {
    @include button.get-btn-h-sm-res();

    height: var(--btn-height);
    width: var(--btn-height);
  }
}

.btn-transparent {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-white {
  @include button.button-variant(white, white);
  box-shadow: 0 0 3px 1px #c0c0c0;
}

.btn-yale-blue {
  @include button.button-variant(variables.$yale-blue, variables.$yale-blue);
}

.btn-w-80 {
  width: auto;
  min-width: 80px !important;
}

.btn-confirm {
  min-height: 31px;
  min-width: 80px;
}

.btn-clear {
  border: 1px solid var(--c);
  color: var(--c);
}

.btn-close {
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.custom-icon {
    --bs-btn-close-bg: none;
    display: flex;
    align-items: center;
    opacity: 1;

    &:hover {
      color: inherit;
    }
  }
}

// circle clear button style
.btn-clear-circle {
  --icon-size: 1.5rem;

  @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    background-image: url("data:image/svg+xml,%3csvg id='circle-xmark' stroke='%239da0ab' width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3cpath d='M9.1712 14.8284L11.9996 12M14.8281 9.17157L11.9996 12M11.9996 12L9.1712 9.17157M11.9996 12L14.8281 14.8284' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3c/svg%3e");
  }

  border: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg id='circle-xmark' stroke='%239da0ab' width='20' height='20' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3cpath d='M9.1712 14.8284L11.9996 12M14.8281 9.17157L11.9996 12M11.9996 12L9.1712 9.17157M11.9996 12L14.8281 14.8284' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  height: var(--icon-size);
  width: var(--icon-size);

  .ng-clear {
    display: none;
  }
}
